import {WebsiteConfiguratie} from './website-configuratie';

export const WarmVlaanderenConfig: WebsiteConfiguratie = {
  initialPage: 'home',
  logo: {
    logoImg: 'Warm-Vlaanderen.svg',
    logoTitle: 'warm vlaanderen',
    logoAlt: 'Warm vlaanderen logo',
  },
  menu: [
    {
      label: 'Home',
      websiteRoute: 'home',
      metaTagDescription: 'Dit is de beschrijving van de home pagina voor warm vlaanderen'
    },
    {
      label: 'Nieuws',
      websiteRoute: 'nieuws',
      metaTagDescription: 'Dit is de beschrijving van de nieuws pagina voor warm vlaanderen'
    }
  ]
};
